





























import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import RulesMixin, { Rule } from "@/mixins/RulesMixin";
import { DATE_FORMAT } from "@/constants";
import moment from "moment";

@Component
export default class DatePickerWithInput extends Mixins(RulesMixin) {
  @Prop() value!: string;
  @Prop({ default: true }) isRequired!: boolean;
  @Prop({ default: null }) label!: string;

  isOpened = false;
  localValue = "";
  dateFormatted = "";

  @Watch("value", { immediate: true })
  onValueChange(value: string) {
    if (value !== "" && value !== null) {
      const momentValue = moment(
        value === "" || value === null ? new Date() : value,
        DATE_FORMAT
      );
      this.dateFormatted = value;
      this.localValue = momentValue.format("YYYY-MM-DD");
    }
  }

  @Watch("localValue")
  onLocalValueChange() {
    this.dateFormatted = this.formatDate(this.localValue);
    this.$emit("input", this.localValue);
  }

  get activeRules(): Rule[] {
    return this.isRequired ? [this.rules.required] : [];
  }

  formatDate(date: string): string {
    const [year, month, day] = date.split("-");
    return `${day}.${month}.${year}`;
  }

  parseDate(date: string) {
    const [day, month, year] = date.split(".");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
}
